@import '../../../../../styles/colors.scss';

.recipientSelect {
  display: flex;
  flex-direction: row;
  align-items: baseline;
  margin-bottom: 2em;
  > * {
    flex-grow: 1;
    margin-left: 1em;
  }
}

.referentialTable {
  display: table;
  border-collapse: collapse;
}

.referentialRow {
  display: table-row;

  &.referentialRowHeader {
    font-weight: 500;
    white-space: nowrap;
  }

  & + & {
    border-top: 1px solid lighten($font-color, 50%);
  }

  &:not(.referentialRowHeader):hover {
    background-color: rgba($brand-primary, 0.1);
  }
}

.referentialCell {
  display: table-cell;
  padding: 0.25em 0;
  vertical-align: top;

  & + & {
    padding-left: 1em;
  }
}

.description {
  display: inline-block;
}

.spinner {
  font-size: 2em;
  padding: 1rem;
  text-align: center;
}
