@import '../../styles/colors';

.label {
  position: relative;
  display: inline-flex;
  align-items: center;
  border-radius: 2px;
  margin-right: 0.5em;

  &:last-child {
    margin-right: 0;
  }

  &.labelFocus {
    box-shadow: 0 0 0 2px $brand-primary;
  }

  &.labelDisabled {
    filter: grayscale(1);
    cursor: not-allowed;
    color: lighten($font-color, 30%);
  }
}

.checkbox {
  position: absolute;
  top: 0;
  left: 0;
  margin: 0;
  padding: 0;
  width: 0;
  height: 0;
  font-size: 0;
  line-height: 0;
  outline: none;
  border: none;
  z-index: -1;
  opacity: 0;
}

.icon {
  display: inline-flex;
  align-items: center;

  &.iconChecked {
    color: $brand-primary;
  }
}

.text {
  display: inline-block;
  margin-left: 0.25em;
}
