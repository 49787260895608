@import '../../styles/colors.scss';

.overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba($font-color, 0.2);
}

.modal {
  display: flex;
  flex-direction: column;
  min-width: 20vw;
  max-width: 90vw;
  max-height: 90vh;
  background-color: white;
  border-radius: 0.25rem;
  box-shadow:
    0 10px 16px 0 rgba($font-color, 0.2),
    0 6px 20px 0 rgba($font-color, 0.2);
}

.header {
  display: flex;
  align-items: flex-start;
}

.title {
  flex: 1;
  font-size: 1.25em;
  padding: 1rem;
}

.closeButton {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.25em;
  font-size: 1.25em;
  border: none;
  background: transparent;
  cursor: pointer;

  &:hover {
    color: $brand-primary;
  }
}

.body {
  flex: 1;
  padding: 0 1em 1em 1em;
  overflow: auto;
}
