.main {
  margin-top: 1em;
  margin-bottom: 0.5em;
}

.button {
  cursor: pointer;
  margin-right: 0.5em;
  margin-bottom: 0.5em;

  :global(.mdi) {
    margin-right: 0.25em;
  }
}
