@import '../../styles/colors.scss';

.selectTree {
  position: relative;
  display: block;

  &.selectTreeInline {
    display: inline-block;
  }
}

.inputBlock {
  position: relative;
}

.input[type='text'] {
  width: 100%;
  padding: 0.25em 2em 0.25em 0.5em;
}

.tree {
  position: absolute;
  top: calc(100% + 2px);
  left: 0;
  right: 0;
  border: 1px solid $border-color;
  border-radius: 2px;
  background-color: white;
  z-index: 1;
  padding: 0.25em 0.25em 0.25em 0;

  &.treeEmpty {
    text-align: center;
  }
}

.icons {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  top: 0;
  bottom: 0;
  right: 0.25em;

  & > * {
    margin-right: 0.25em;
  }

  :global(.mdi-loading) {
    font-size: 0.95em;
  }
}

.closeButton {
  border: none;
  background: transparent;
  padding: 0;
  cursor: pointer;

  &:hover {
    color: $brand-primary;
  }
}
