/* stylelint-disable no-descending-specificity */
@import '../../styles/colors.scss';

.tree {
  :global(.rc-tree-indent-unit) {
    display: inline-block;
    width: 1em;
    height: 0;
    line-height: 0;
  }

  &:global(.rc-tree-focused) {
    box-shadow: 0 0 0 1px $brand-primary;
    border-color: $brand-primary;
  }

  :global(.rc-tree-treenode) {
    display: flex;
    align-items: center;
    height: 22px;
  }

  :global(.rc-tree-node-content-wrapper) {
    flex: 1;

    &:hover {
      background-color: $hover-color;
    }
  }

  :global(.rc-tree-title) {
    display: inline-block;
    cursor: default;
    width: 100%;
    line-height: 0.8em;
  }

  :global(.rc-tree-treenode-active) {
    background-color: $hover-color;
  }

  :global(.rc-tree-switcher) {
    &:hover {
      color: $brand-primary;
    }
  }

  :global(.rc-tree-switcher-noop .mdi-blank) {
    width: 1em;
  }
}
