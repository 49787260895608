@import '../../styles/colors';

.button {
  display: inline-flex;
  align-items: center;
  padding: 0.5em 1em;
  outline-color: $brand-primary;
  cursor: pointer;

  &:disabled,
  &[disabled] {
    filter: grayscale(1);
    cursor: not-allowed;
  }

  &.buttonPrimary {
    color: white;
    background-color: $brand-primary;
    border: none;
    border-radius: 2px;
    font-weight: 500;

    &:hover,
    &:focus {
      background-color: darken($brand-primary, 10%);
    }
  }

  &.buttonSecondary {
    color: $brand-primary;
    background-color: transparent;
    border: 2px solid $brand-primary;
    border-radius: 2px;
    font-weight: 500;

    &:hover,
    &:focus {
      border-color: darken($brand-primary, 10%);
      color: darken($brand-primary, 10%);
    }
  }

  &.buttonLink {
    border: none;
    text-decoration: underline;
    color: $brand-secondary;
    background: none;
    padding: 0;

    &:hover {
      color: $brand-primary;
    }
  }
}
