@import '../../../../../styles/colors.scss';

.fieldName {
  display: inline-flex;
  align-items: center;
  color: $brand-danger;
  background-color: rgba($brand-danger, 0.1);
  font-weight: 700;
  padding: 0 0.4em;
  border-radius: 2px;
  word-break: break-all;
}
