.main {
  display: flex;
  align-items: center;
  justify-content: center;
}

.form {
  display: flex;
  flex-direction: column;
  align-items: center;

  & > label {
    margin-bottom: 1em;
  }
}

.logo {
  height: 120px;
  margin-bottom: 4em;
}

.textLabel {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;

  & > span {
    padding-bottom: 0.2em;
  }

  & > input {
    min-width: 300px;
  }
}

.submit {
  margin-bottom: 1em;

  i {
    display: inline-block;
    margin-right: 0.2em;
  }
}
