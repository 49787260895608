/* stylelint-disable no-descending-specificity */
@import 'react-date-picker/dist/DatePicker.css';
@import 'react-calendar/dist/Calendar.css';
@import '../../styles/colors.scss';

.datePicker {
  &.datePickerNoClear {
    :global(.react-date-picker__clear-button) {
      cursor: default;
    }
  }

  :global(.react-date-picker__wrapper) {
    border: 1px solid $border-color;
    border-radius: 2px;
  }

  :global(.react-date-picker__calendar) {
    border: none;
    margin-top: 2px;
  }

  :global(.react-date-picker__inputGroup__input:invalid) {
    background-color: lighten($brand-danger, 38%);
  }

  :global(.react-calendar) {
    border: 1px solid $border-color;
    border-radius: 2px;
  }

  :global(.react-calendar__tile--now) {
    color: $font-color;
    background-color: lighten($brand-primary, 40%);

    &:hover {
      color: $font-color;
      background-color: lighten($brand-primary, 30%);
    }
  }

  :global(.react-calendar__tile--active) {
    color: white;
    background-color: $brand-primary;

    &:enabled {
      &:focus,
      &:hover {
        color: white;
        background-color: $brand-secondary;
      }
    }
  }

  :global(.react-calendar__month-view__days__day--weekend) {
    color: darken($brand-danger, 10%);
  }
}

.clearIcon {
  &:hover {
    color: $brand-primary;
  }

  &.clearIconDisabled {
    color: lighten($font-color, 40%);
  }
}

.calendarIcon {
  &:hover {
    color: $brand-primary;
  }
}
