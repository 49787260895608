@import '../../styles/colors';

.main {
  padding: 2em;
}

.title {
  display: flex;
  align-items: center;
  color: $brand-danger;

  i {
    margin-right: 0.5em;
  }
}
