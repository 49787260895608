@import '../../../../../styles/colors.scss';

.sampleTypes {
  display: flex;
  align-items: center;
  padding-bottom: 0.25em;
}

.samplesTitle {
  font-weight: 500;
}

.sampleButton {
  color: $brand-secondary;
  padding: 0;
  background: transparent;
  border: none;
  margin-left: 1em;
  cursor: pointer;

  &:hover {
    color: $brand-primary;
  }

  &.sampleButtonSelected {
    text-decoration: underline;
  }
}

.samplesContent {
  padding: 1em;
  margin: 0;
  border-width: 1px;
  border-style: dashed;
  border-color: lighten($font-color, 50%);
  white-space: pre-wrap;
  word-break: break-all;
}
