@import '../../../../../styles/colors';

.filterBody {
  display: table-cell;
  padding: 0.5em 0;
  vertical-align: top;
}

.filterLabel {
  display: table-cell;
  width: 1px;
  padding: 0.5em 2em 0.5em 0;
  white-space: nowrap;
  vertical-align: top;

  &.filterLabelMiddle {
    vertical-align: middle;
  }
}

.filter {
  display: table-row;

  &.filterLoading {
    label > span {
      background-color: lighten($font-color, 30%);
      color: transparent;
      height: 0.5em;
      line-height: 0.5em;
    }

    @keyframes fade {
      50% {
        opacity: 0.1;
      }
    }
    .filterBody {
      opacity: 0.9;
      animation: fade 2s ease-in-out 0s infinite;
    }
  }
}
