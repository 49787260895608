@import '../../styles/colors.scss';

.wrapper {
  position: relative;
}

.block {
  display: block;
}

.inline {
  display: inline-block;
}

.textInput {
  width: 100%;
  padding: 0.25em 2em 0.25em 0.5em;
}

.clearButton {
  position: absolute;
  display: flex;
  top: 0;
  bottom: 0;
  right: 0.5em;
  align-items: center;
  justify-content: center;
  border: none;
  background: transparent;
  padding: 0;
  cursor: pointer;

  &:hover {
    color: $brand-primary;
  }
}
