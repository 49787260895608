@import '../../../../../styles/colors.scss';

.field {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  & + & {
    border-top: 1px solid lighten($font-color, 50%);
    margin-top: 1em;
    padding-top: 1em;
  }
}

.attributesBlock {
  flex-basis: 25%;
  max-width: 25%;
  padding: 1em;
}

.descriptionBlock {
  flex-basis: 25%;
  max-width: 25%;
  padding: 1em;
}

.samplesBlock {
  flex-basis: 50%;
  max-width: 50%;
  padding: 0 1em 1em 1em;
}

@media (max-width: 1400px) {
  .attributesBlock {
    flex-basis: 50%;
    max-width: 50%;
  }

  .descriptionBlock {
    flex-basis: 50%;
    max-width: 50%;
  }

  .samplesBlock {
    flex-basis: 100%;
    max-width: 100%;
  }
}

.description {
  word-break: break-word;
}

.description + .referentialText {
  padding-top: 1em;
}

.referentialList {
  margin: 0.5em 0;
  list-style: disc;
}

.referentialButton {
  color: $brand-secondary;
  padding: 0;
  background: transparent;
  border: none;
  text-decoration: underline;
  cursor: pointer;
  word-break: break-all;
  text-align: left;

  &:hover {
    color: $brand-primary;
  }
}

.subField {
  flex-basis: 100%;
  max-width: 100%;
  padding-left: 1em;

  &:last-child {
    margin-bottom: 1em;

    & > .subFieldBody {
      border-bottom-width: 1px;
    }
  }

  &.subFieldRoot {
    padding-right: 1em;
  }
}

.subFieldText {
  padding: 0 0 0.25em 1em;
  font-weight: 500;
}

.subFieldBody {
  display: flex;
  flex-direction: column;
  border-width: 1px 0 0 1px;
  border-style: dashed;
  border-color: lighten($font-color, 50%);

  &.subFieldBodyRoot {
    border-right-width: 1px;
  }
}

.subFieldHeader {
  display: flex;
  align-items: center;
  padding: 0.25em 0;
  border: none;
  background: transparent;
  cursor: pointer;

  &:hover {
    background-color: rgba($brand-primary, 0.1);
  }
}
