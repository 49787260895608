@import '../../../styles/colors';

.loginError {
  display: flex;
  align-items: flex-start;
  background-color: $brand-danger;
  border-radius: 2px;
  color: white;
  max-width: 420px;
  padding: 1em;
}

.icon {
  margin-right: 0.5em;
}

.body {
  flex-grow: 1;
  overflow: hidden;
}

.detailsButton {
  margin: 0;
  padding: 0;
  outline: none;
  border: none;
  background: transparent;
  text-decoration: underline;
  color: white;
}

.stack {
  overflow: auto;
  max-width: 100%;

  pre {
    margin: 1em 0 0 0;
    padding: 0;
    font-size: 0.8em;
  }
}
