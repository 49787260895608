@import '../../../../../styles/colors.scss';

.attributeList {
  list-style: none;
  margin: 0;
  padding: 0;

  & + & {
    margin-top: 0.5em;
  }
}

.attributeItem {
  display: flex;
  align-items: flex-start;
}

.attributeLabel {
  font-weight: 500;
  padding-right: 0.5em;
  white-space: nowrap;
}

.switchRootButton {
  color: $brand-secondary;
  padding: 0;
  background: transparent;
  border: none;
  text-decoration: underline;
  cursor: pointer;

  &:hover {
    color: $brand-primary;
  }
}
