/* stylelint-disable no-descending-specificity */
@import '../../styles/colors.scss';

.dateRange {
  display: inline-flex;
  align-items: center;
  flex-wrap: wrap;
}

.dateFrom,
.dateTo {
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  flex-wrap: nowrap;
}

.dateFrom {
  margin-right: 1em;
}

.label {
  display: inline-block;
  padding: 0 1em;

  &:first-child {
    padding-left: 0;
  }
}
