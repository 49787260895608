@import '../../../../styles/colors';

.paginationFlexContainer {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-content: stretch;
  align-items: center;
  padding: 8px;
  background-color: $hover-color;
  font-weight: bold;

  i {
    font-size: x-large;
  }
}

.miniPad {
  padding-right: 5px;
  padding-left: 5px;
  opacity: 0.7;
}

.paginationButtons {
  display: flex;
  flex-direction: row;
  align-items: center;
  span {
    font-weight: bold;
  }
}
