@import '../../../../styles/colors.scss';

@keyframes indeterminateLoadingAnimation {
  from {
    left: -30%;
    width: 30%;
  }
  50% {
    width: 30%;
  }
  70% {
    width: 70%;
  }
  80% {
    left: 50%;
  }
  95% {
    left: 120%;
  }
  to {
    left: 100%;
  }
}

.loadingBar {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 100;
  background: linear-gradient(
    to right,
    darken($brand-primary, 10%),
    $brand-primary
  );
  background-size: 100%;
  height: 4px;
  border-radius: 2px;
  animation: indeterminateLoadingAnimation 3s ease infinite;
}
