@import './styles/colors';

* {
  box-sizing: border-box;
}

body {
  margin: 0;
  padding: 0;
  font-family:
    'Roboto',
    -apple-system,
    BlinkMacSystemFont,
    'Segoe UI',
    'Oxygen',
    'Ubuntu',
    'Cantarell',
    'Fira Sans',
    'Droid Sans',
    'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 16px;
  font-weight: 400;
  color: $font-color;
}

code,
pre {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

input,
button {
  outline-color: $brand-primary;
}

input[type='text'],
input[type='password'] {
  padding: 0.25em 0.5em;
  border: 1px solid $border-color;
  border-radius: 2px;
}

a {
  color: darken($brand-primary, 20%);
  text-decoration: underline;
  cursor: pointer;

  &:hover {
    color: $brand-primary;
  }
}

.mdi {
  display: inline-block;
  font-size: 1.25em;
  height: 1em;
  line-height: 1em;
  vertical-align: middle;
}
